<template>
  <div class="join_step2">
    <div style="width: 600px; margin: 20px auto">
      <template v-if="alertShow">
        <el-alert type="error" show-icon :closable="false">
          <span slot="title">资料审核不通过，{{joinForm.joinin_message}}</span>
        </el-alert>
      </template>
      <el-form label-width="140px" :model="joinForm" ref="joinFormRef" :rules="rules">
        <el-form-item label="店铺名称:" prop="store_name">
          <el-input type="text" placeholder="请输入店铺名称" v-model="joinForm.store_name"></el-input>
        </el-form-item>
        <el-form-item label="店铺类型:" prop="store_type" style="text-align: left">
          <el-radio-group v-model="joinForm.store_type">
            <el-radio :label="0">公司</el-radio>
            <el-radio :label="1">个人</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开店时长:" prop="joinin_year">
          <el-input-number style="width: 100%" v-model="joinForm.joinin_year" controls-position="right" :min="1" placeholder="请输入开店时长"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="店铺分类:" prop="storeclass_id">
                 <el-select style="width: 100%" v-model="joinForm.storeclass_id" placeholder="请选择店铺分类">
                   <el-option v-for="(value, key) in categoryInfo" :key="key" :label="value" :value="key">
                   </el-option>
                 </el-select>
               </el-form-item> -->

        <el-form-item label="店铺分类" prop="storeclass_id">
          <el-select v-model="joinForm.storeclass_id" placeholder="请选择" style="width: 100%" clearable>
            <el-option v-for="(value,key) in categoryInfo" :key="key" :label="value" :value="key">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="付款凭证:" prop="paying_money_certificate">
            <el-input type="text" placeholder="请输入付款凭证" v-model="joinForm.paying_money_certificate"></el-input>
          </el-form-item>
          <el-form-item label="付款凭证说明:" prop="paying_money_certificate_explain">
            <el-input type="text" placeholder="请输入付款凭证说明" v-model="joinForm.paying_money_certificate_explain"></el-input>
          </el-form-item>
          <el-form-item label="付款金额:" prop="paying_amount">
            <el-input type="text" placeholder="请输入付款金额" v-model="joinForm.paying_amount"></el-input>
          </el-form-item> -->
        <el-form-item label="公司名称:" prop="company_name">
          <el-input type="text" placeholder="请输入公司名称" v-model="joinForm.company_name"></el-input>
        </el-form-item>
        <el-form-item label="公司地区:" prop="company_address_id">
          <el-cascader style="width: 100%" :options="cityInfo" v-model="joinForm.company_address_id" placeholder="请选择公司地区">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址:" prop="company_address_detail">
          <el-input type="text" placeholder="请输入详细地址" v-model="joinForm.company_address_detail"></el-input>
        </el-form-item>
        <el-form-item label="注册资金:" prop="company_registered_capital">
          <el-input type="number" placeholder="请输入注册资金" v-model.number="joinForm.company_registered_capital"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名:" prop="contacts_name">
          <el-input type="text" placeholder="请输入联系人姓名" v-model="joinForm.contacts_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话:" prop="contacts_phone">
          <el-input type="text" placeholder="请输入联系人电话" v-model.number="joinForm.contacts_phone"></el-input>
        </el-form-item>
        <el-form-item label="联系人邮箱:" prop="contacts_email">
          <el-input type="text" placeholder="请输入联系人邮箱" v-model="joinForm.contacts_email"></el-input>
        </el-form-item>
        <el-form-item label="营业执照号:" prop="business_licence_number">
          <el-input type="text" placeholder="请输入营业执照号" v-model="joinForm.business_licence_number"></el-input>
        </el-form-item>
        <el-form-item label="营业执照所在地:" prop="business_licence_address">
          <el-input type="text" placeholder="请输入营业执照所在地" v-model="joinForm.business_licence_address"></el-input>
        </el-form-item>
        <el-form-item label="营业执照有效期:">
          <el-col :span="11">
            <el-form-item prop="business_licence_start">
              <el-date-picker style="width: 100%" v-model="joinForm.business_licence_start" type="date" placeholder="请选择有效期开始日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions1">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item prop="business_licence_end">
              <el-date-picker style="width: 100%" v-model="joinForm.business_licence_end" type="date" placeholder="请选择有效期结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions2">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="法定经营范围:" prop="business_sphere">
          <el-input type="textarea" placeholder="请输入法定经营范围" v-model="joinForm.business_sphere"></el-input>
        </el-form-item>
        <el-form-item label="执照电子版:" prop="business_licence_electronic">
          <el-input v-model="joinForm.business_licence_electronic" v-if="false"></el-input>
          <el-upload class="licence-uploader" :show-file-list="false" action="/store/upload/licence" :before-upload="beforeUpload" :headers="headers" :on-success="uploadSuccess" name="image">
            <img v-if="joinForm.business_licence_electronic" :src="joinForm.business_licence_electronic" class="licence" />
            <i v-else class="el-icon-plus licence-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="银行开户名:" prop="bank_account_name">
          <el-input type="text" placeholder="请输入银行开户名" v-model="joinForm.bank_account_name"></el-input>
        </el-form-item>
        <el-form-item label="公司银行账号:" prop="bank_account_number">
          <el-input type="text" placeholder="请输入公司银行账号" v-model="joinForm.bank_account_number"></el-input>
        </el-form-item>
        <el-form-item label="开户银行支行名称:" prop="bank_name">
          <el-input type="text" placeholder="请输入开户银行支行名称" v-model="joinForm.bank_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行所在地:" prop="bank_address">
          <el-input type="text" placeholder="请输入开户银行所在地" v-model="joinForm.bank_address"></el-input>
        </el-form-item>
        <el-form-item label="结算账号:" style="text-align: left">
          <el-radio-group v-model="joinForm.is_settlement_account">
            <el-radio :label="1">开户账号</el-radio>
            <el-radio :label="2">独立账号</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-collapse-transition>
          <div v-if="joinForm.is_settlement_account === 2">
            <el-form-item label="银行开户名:" prop="settlement_bank_account_name">
              <el-input type="text" placeholder="请输入银行开户名" v-model="joinForm.settlement_bank_account_name"></el-input>
            </el-form-item>
            <el-form-item label="公司银行账号:" prop="settlement_bank_account_number">
              <el-input type="text" placeholder="请输入公司银行账号" v-model="joinForm.settlement_bank_account_number"></el-input>
            </el-form-item>
            <el-form-item label="开户银行支行名称:" prop="settlement_bank_name">
              <el-input type="text" placeholder="请输入开户银行支行名称" v-model="joinForm.settlement_bank_name"></el-input>
            </el-form-item>
            <el-form-item label="开户银行所在地:" prop="settlement_bank_address">
              <el-input type="text" placeholder="请输入开户银行所在地" v-model="joinForm.settlement_bank_address"></el-input>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
      <!-- 提交按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="handleSubmit()">确认资料，提交审核</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { entryStep_2Api, joinFormSubmitApi } from '@/api/api'
  export default {
    data() {
      return {
        pickerOptions1: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        pickerOptions2: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        alertShow: false,
        headers: {
          authorization: `Bearer ${localStorage.sefpc_token}`
        },
        selectCity: [],
        cityInfo: [],
        categoryInfo: [],
        // 表单数据
        joinForm: {
          store_type: 0, //店铺类型:0公司，1个人
          company_name: '', //公司名称
          company_province_id: '', //所在地省ID
          company_address_id: [], //地址区域ID
          company_address_detail: '', //详细地址
          company_registered_capital: '', //注册资金
          contacts_name: '', //联系人姓名
          contacts_phone: '', //联系人电话
          contacts_email: '', //联系人邮箱
          business_licence_number: '', //营业执照号
          business_licence_address: '', //营业执所在地
          business_licence_start: '', //营业执照有效期开始
          business_licence_end: '', //营业执照有效期结束
          business_sphere: '', //法定经营范围
          business_licence_electronic: '', //营业执照电子版
          bank_account_name: '', //银行开户名
          bank_account_number: '', //公司银行账号
          bank_name: '', //开户银行支行名称
          bank_address: '', //开户银行所在地
          is_settlement_account: 1, //开户行账号是否为结算账号 1-开户行就是结算账号    2-独立的计算账号
          settlement_bank_account_name: '', //结算银行开户名
          settlement_bank_account_number: '', //结算公司银行账号
          settlement_bank_name: '', //结算开户银行支行名称
          settlement_bank_address: '', //结算开户银行所在地
          store_name: '', //店铺名称
          joinin_year: '', //开店时长(年)
          storeclass_id: '', //店铺分类编号
          //   paying_money_certificate: '', //付款凭证
          //   paying_money_certificate_explain: '', //付款凭证说明
          //   paying_amount: '', //付款金额
        },
        // 表单验证
        rules: {
          store_name: [
            { required: true, message: '请输入店铺名称', trigger: 'blur' }
          ],
          storeclass_id: [
            { required: true, message: '请选择店铺分类', trigger: 'blur' }
          ],
          company_name: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          company_address_id: [
            { required: true, message: '请选择公司地区', trigger: 'blur' }
          ],
          company_address_detail: [
            { required: true, message: '请输入详细地址', trigger: 'blur' }
          ],
          company_registered_capital: [
            { required: true, message: '请输入注册资金', trigger: 'blur' },
            { type: 'number', message: '注册资金必须为数字值', trigger: ['blur', 'change'] }
          ],
          contacts_name: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' }
          ],
          contacts_phone: [
            { required: true, message: '请输入联系人电话', trigger: 'blur' },
            { type: 'number', message: '请输入正确的电话号码', trigger: ['blur', 'change'] }
          ],
          contacts_email: [
            { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
          ],
          business_licence_number: [
            { required: true, message: '请输入营业执照号', trigger: 'blur' }
          ],
          business_licence_address: [
            { required: true, message: '请输入营业执照所在地', trigger: 'blur' }
          ],
          business_licence_start: [
            { required: true, message: '请选择有效期开始日期', trigger: 'blur' }
          ],
          business_licence_end: [
            { required: true, message: '请选择有效期结束日期', trigger: 'blur' }
          ],
          business_sphere: [
            { required: true, message: '请输入法定经营范围', trigger: 'blur' }
          ],
          business_licence_electronic: [
            { required: true, message: '请上传营业执照', trigger: 'blur' }
          ],
          bank_account_name: [
            { required: true, message: '请输入银行开户名', trigger: 'blur' }
          ],
          bank_account_number: [
            { required: true, message: '请输入公司银行账号', trigger: 'blur' }
          ],
          bank_name: [
            { required: true, message: '请输入开户银行支行名称', trigger: 'blur' }
          ],
          bank_address: [
            { required: true, message: '请输入开户银行所在地', trigger: 'blur' }
          ],
          settlement_bank_account_name: [
            { required: true, message: '请输入银行开户名', trigger: 'blur' }
          ],
          settlement_bank_account_number: [
            { required: true, message: '请输入公司银行账号', trigger: 'blur' }
          ],
          settlement_bank_name: [
            { required: true, message: '请输入开户银行支行名称', trigger: 'blur' }
          ],
          settlement_bank_address: [
            { required: true, message: '请输入开户银行所在地', trigger: 'blur' }
          ]
        }
      }
    },
    created() {
      this.getEntryData()
    },
    methods: {
      // 提交审核
      handleSubmit() {
        this.joinForm.company_province_id = this.joinForm.company_address_id[0]
        this.$refs.joinFormRef.validate((valid) => {
          if (!valid) return false;
          joinFormSubmitApi(this.joinForm).then(res => {
            console.log(res)
            if (res.data.status != 10000) return this.$message.error(res.data.message)
            this.$message.success(res.data.message)
            this.$router.push('/store/join/step_3')
          }).catch(err => {
            this.$message.error(err.message)
          })
        });
      },
      //   上传图片成功
      uploadSuccess(res) {
        this.joinForm.business_licence_electronic = res.result.fileName[0]
        this.$message.success(res.message)
      },
      //  保存图片url
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' ? true : file.type === 'image/png' ? true : false;
        const isLt2M = file.size / 1024 / 1024 <= 1;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG或PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!');
        }
        return isJPG && isLt2M;
      },
      // 获取商家入驻表单所需数据
      getEntryData() {
        this.$emit('getActiveStep', 1)
        entryStep_2Api().then(res => {
          if (res.data.status === 90004) return this.$router.go(-1)
          this.cityInfo = res.data.result.cityInfo
          this.categoryInfo = res.data.result.categoryInfo
          if (res.data.result.entryInfo) {
            this.joinForm = res.data.result.entryInfo
            this.joinForm.storeclass_id = res.data.result.entryInfo.storeclass_id + ''
            this.joinForm.company_address_id = this.joinForm.company_address_id.map(String)
            this.joinForm.bank_account_number = Number(this.joinForm.bank_account_number)
            this.joinForm.company_registered_capital = Number(this.joinForm.company_registered_capital)
            this.joinForm.contacts_phone = Number(this.joinForm.contacts_phone)
            this.alertShow = true
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .join_step2 {

    .el-alert {
      height: 40px;
      margin-bottom: 20px;
    }
  }

  .licence-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
  }

  .licence-uploader:hover {
    border-color: #409eff;
  }

  .licence-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 178px;
    text-align: center;
  }

  .licence {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>